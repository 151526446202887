import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";

const Description = Styled.p`
  font-size: 2rem;
  line-height: 1.25;
  font-weight: 400;
  letter-spacing: -0.2px;
  margin: 0 0 1.5rem 0;
  
  ${Media.greaterThan("large")`
    font-size: 2.6rem;
    margin: 0 0 3rem 0;
  `}
`;

export default Description;
